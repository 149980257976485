import React from "react";
import CSS from 'csstype';
import '../../Util.css';
import { Button } from "react-bootstrap";

class Technology {
    label : String;
    subtech : Array<String>;

    constructor(label: String, subtech: Array<String>) {
        this.label = label;
        this.subtech = subtech;
    }

}

const technologies : Array<Technology> = [
    new Technology("Java", [
        "Spring",
        "Camel",
        "Guava",
        "ReactiveX"
    ]),
    new Technology("Groovy", [
        "Spock"
    ]),
    new Technology("C#", [
        "Microsoft Dynamics 365",
        "NUnit"
    ]),
    new Technology("Virtualization", [
        "Docker",
        "Kubernetes",
    ]),
    new Technology("Statistics & AI", [
        "R-Lang",
        "Python"
    ]),
    new Technology("Tooling", [
        "Bash",
        "Vim",
        "Emacs"
    ]),
    new Technology("IDE's", [
        "Jetbrains Intellij",
        "Visual Studio",
        "Visual Studio Code"
    ])
];

const projectSkills : Array<Technology> = [
    new Technology("Team lead", [
        "Delegation",
        "Urgency management",
        "Reporting",
        "Team protector"
    ]),
    new Technology("Project lead", [
        "Priorization",
        "Project estimation",
        "Allocation",
        "Governance"
    ]),
    new Technology("Consultant", [
        "Pragmatic",
        "Referent",
        "Responsible",
        "Analytic"
    ]),
    new Technology("Developer", [
        "Adaptive",
        "Reviewer",
        "Quality Assurance",
        "High Quality Code",
        "Integrations"
    ]),
    new Technology("Architecture", [
        "Enterprise patterns",
        "Programming guidelines",
        "System design",
        "Integrations",
        "Documentation"
    ])
];

const techMatrixStyle: CSS.Properties = {
    textAlign: "center"
}

export function TechnologyList() {
    return (
        <ul className="HorizontalList">
            {technologies.map((value, index) => {
                return outerTech(value, index);
            })}
        </ul>
    )
}

export function ProjectSkills() {
    return (
        <ul className="HorizontalList">
            {projectSkills.map((value, index) => {
                return outerTech(value, index);
            })}
        </ul>
    )
}

const noStyleUL: CSS.Properties = {
    display: "table-row"
};

const noStyleLI: CSS.Properties = {
    listStyleType: "none",
    paddingTop: "1em",
    paddingLeft: "0.5em",
    paddingRight: "0.5em"
};

function outerTech(tech : Technology, i : number) {
    return (<li key={i}>
        <Button variant="success">{tech.label}</Button>
        <ul style={noStyleUL}>
            {tech.subtech.map((value, index) => {
                return innerTech(value, index);
            })}
        </ul>
    </li>)
}

function innerTech(tech : String, i : number) {
    return (<li key={i} style={noStyleLI}>
        <Button variant="warning">{tech}</Button>
    </li>)
}



export default TechnologyList;