import React from "react";
import './SoftwareEngineer.css';
import { TechnologyList, ProjectSkills } from './subsections/technologies';
import { Container, Col, Row } from 'react-bootstrap';
import "../Util.css";

function SoftwareEngineering() {
    const yearsSince = 0;

    return (
        <Container>
            <Row >
                <Col className="TextCenter">
                    <h1 >Software Engineering</h1>
                </Col>
            </Row>
            <Row>
                <Col className="Fortified">
                    <p>
                        I've been working with Software engineering for the last 3 and a half year besides the 5 years during my education.
                        <br/>
                        I've been working with multiple Technologies
                        <h3 className="TextCenter">Technologies</h3>
                        <TechnologyList/>
                        Working at Netcompany A/S for the last 3 and a half year I've also become really aquitant and familiar with a long list
                        of both managerial skills, and personal skills.
                        <h3 className="TextCenter">Managerial Skills</h3>
                        <ProjectSkills/>
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col className="Fortified TextCenter">
                    <h2>University Degree</h2>
                    <span>
                        Master of Science<br/>
                        Cand.Polyt Software Engineering<br/>
                        University of Southern Denmark<br/>
                        2012 - 2017</span>
                </Col>
                <Col className="Fortified TextCenter">
                    <h2>Student Job</h2>
                    <span>
                        Student Developer TV2 - Denmark<br/>
                        Backend development<br/>
                        2013-2017
                    </span>
                </Col>
            </Row>
        </Container>
    )
}

export default SoftwareEngineering;