import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import logo from './img/face.jpg';
import './App.css';
import './Util.css';
import SoftwareEngineer from './pages/softwareEngineer';
import Contact from './pages/contact';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Router>
      <div>
        <nav className="Sideways">
          <li>
             <Link to="/">Home</Link>
          </li>
          <li>
             <Link to="/softwareengineer">Software Engineering</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </nav>
      </div>
      <Switch>
        <Route exact path="/">
          <div className="App">
            <header className="App-header">
              <img src={logo} className="App-logo" alt="logo" />
              <p>
                Welcome to the world of Reibke!
              </p>
              <ul>
                <li>Software Engineering</li>
                <li>Consultancy</li>
                <li>Gaming enthusiast</li>
              </ul>
            </header>
          </div>
        </Route>
        <Route path="/softwareengineer">
          <div className="Page">
            <header>
              <SoftwareEngineer />
            </header>
          </div>
        </Route>
        <Route path="/contact">
          <div className="Page">
            <header>
              <Contact />
            </header>
          </div>
        </Route>
      </Switch>
    </Router>
    
  );
}

export default App;
