import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container, Col, Row, Button } from 'react-bootstrap'
import "../Util.css"


function Contact() {
    return (
        <Container>
            <h1 className="TextCenter">Contact</h1>
            <Row className="justify-content-md-center">
                <Col className="Fortified TextCenter">
                    <p>
                        For any contact inquiring business, please reach out for me on LinkedIn, link below.
                    </p>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <ul className="HorizontalList">
                    <li>
                        <Button variant="primary">
                            <a className="nostyle" 
                                    href="https://www.linkedin.com/in/nikolaj-schaldemose-reibke-444a683a/"
                                    target="_blank">
                                LinkedIn <FontAwesomeIcon icon={ faLinkedin }/>
                            </a>
                        </Button>
                    </li>
                    <li>
                        <Button variant="primary">
                            <a className="nostyle" 
                                    href="https://github.com/Wisienkas"
                                    target="_blank">
                                Github <FontAwesomeIcon icon={ faGithub }/>
                            </a>
                        </Button>
                    </li>
                </ul>
            </Row>
        </Container>
    )
}

export default Contact;